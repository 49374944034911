import "@uiw/react-textarea-code-editor/dist.css";
import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "pages/Authentication/LoginPage";
import PasswordResetPage from "pages/Authentication/PasswordResetPage";
import NotFoundPage from "pages/NotFoundPage";
import PrivateRoute from "components/PrivateRoute";
import PasswordResetEmailPage from "pages/Authentication/PasswordResetEmailPage";
import ZendeskRedirectPage from "pages/Authentication/ZendeskRedirectPage";
import RootPage from "pages/RootPage";
import SSOLoginCallback from "pages/Authentication/SSOLoginCallback";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/health">
          <div>OK</div>
        </Route>

        <Route exact path="/login">
          <LoginPage />
        </Route>

        <Route exact path="/forgotpassword">
          <PasswordResetEmailPage />
        </Route>

        <Route exact path="/resetpassword">
          <PasswordResetPage />
        </Route>

        <Route exact path="/helpdesklogin">
          <ZendeskRedirectPage />
        </Route>

        <Route exact path="/sso_callback">
          <SSOLoginCallback />
        </Route>

        <PrivateRoute path="/">
          <RootPage />
        </PrivateRoute>

        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;