import Box from "@mui/material/Box";
import withFallbackValue from "utils/withFallbackValue";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "./format";
import langcodes from "./langcodes.json";

import { ReactComponent as DashboardIcon } from "icons/UI/Dashboard.svg";
import { ReactComponent as NarrativeFeedIcon } from "icons/UI/NarrativeFeed.svg";
import { ReactComponent as BuildIcon } from "icons/UI/builder.svg";
import { ReactComponent as AnalyzeIcon } from "icons/UI/analyze.svg";
import { ReactComponent as GraphsIcon } from "icons/UI/graphs.svg";
import { ReactComponent as ReportsIcon } from "icons/UI/ReportIcon.svg";
import { ReactComponent as DataIcon } from "icons/UI/data.svg";

export const DRAWER_CLOSED_WIDTH = 64;

export const NARRATIVE_FEED_KEY = "has_narrative_feed";
export const JWT_TOKEN_FIELD = "jwtTokenUser";
export const JWT_REFRESH_TOKEN_FIELD = "jwtRefreshTokenUser";
export const SSO_USER_FIELD = "ssoUser";
export const ACCEPTED_FILES = [".csv"];
export const DEFAULT_PROFILE = {
  id: 0,
  name: "Blackbird  default",
  isDefault: true,
};
export const DEFAULT_CLASSIFIER = {
  id: 0,
  name: "Blackbird  default",
  isDefault: true,
};
export const HARMFUL_ENGAGEMENT_POSTS_LABEL = "Engagements on High Risk Posts";
export const DEFAULT_SORT = "engagement";

export const LOCAL_STORAGE_KEYS = {
  USER: "user",
};

export const PARENT_USERS = "parent_users";

export const ROUTE_PATHES = {
  overview: "overview",
  narratives: "narratives",
  discover: "discover",
  refine: "refine",
  analyze: "analyze",
  visualize: "visualize",
  report: "report",
  data: "data",
  narrativeFeed: "narrativeFeed",
  narrativeFeedDetails: "details",
};

export const ROUTES = [
  {
    name: "Narrative Feed",
    id: "NarrativeFeedBtn",
    path: ROUTE_PATHES.narrativeFeed,
    icon: NarrativeFeedIcon,
    children: [
      {
        name: "Details",
        path: ROUTE_PATHES.narrativeFeedDetails,
      },
    ],
  },
  {
    divider: true,
    name: "Overview",
    id: "DashboardButton",
    path: ROUTE_PATHES.overview,
    icon: DashboardIcon,
  },
  {
    name: "Narratives",
    id: "NarrativesBtn",
    path: ROUTE_PATHES.narratives,
    icon: BuildIcon,
    children: [
      {
        name: "Discover",
        path: ROUTE_PATHES.discover,
      },
      { name: "Refine", path: ROUTE_PATHES.refine },
    ],
  },
  {
    name: "Analyze",
    id: "AnalyzeTab",
    path: ROUTE_PATHES.analyze,
    icon: AnalyzeIcon,
  },
  {
    name: "Network",
    path: ROUTE_PATHES.visualize,
    id: "VisualizeTab",
    icon: GraphsIcon,
  },
  {
    name: "Reports",
    id: "ReportsBtn",
    path: ROUTE_PATHES.report,
    icon: ReportsIcon,
  },
  {
    divider: true,
    name: "Data",
    path: ROUTE_PATHES.data,
    id: "DataBtn",
    icon: DataIcon,
    url: "/manage/projects",
    children: [
      {
        name: "Projects",
        path: "/projects",
      },
    ],
  },
];

export const ALL_PROJECT = "All";
export const ALL_PROJECT_INDEX = 0;

// display names for toxicity
// export const TOXICITY_LABEL_MAPPINGS = {
//   anti_lgbtq: "Anti-LGBTQ",
//   anti_semitic: "Anti-Semitic",
//   anti_blackness: "Anti-Blackness",
// };

// TODO we should use only nouns or only adjectives instead of mixing them.
export const EMOTION_MAPPINGS = {
  happiness: "Happy",
  anger: "Angry",
  disgust: "Disgust",
  sadness: "Sad",
  surprise: "Surprised",
  neutral_emotion: "Neutral",
  fear: "Fear",
};

export const EMOTIONS = Object.keys(EMOTION_MAPPINGS);

export const SENTIMENT_MAPPINGS = {
  positive_sentiment: "Positive",
  neutral_sentiment: "Neutral",
  negative_sentiment: "Negative",
};

export const SENTIMENTS = Object.keys(SENTIMENT_MAPPINGS);

export const DEFAULT_NARRATIVE = {
  start_date: DEFAULT_START_DATE,
  end_date: DEFAULT_END_DATE,
  name: ALL_PROJECT,
  keywords: "*",
  analyze_filters: {
    arr: [],
    obj: {},
    serialized: {},
  },
  isDefault: true,
};

export const DEFAULT_TOPIC_FOLDER = "Default";
export const POST_COUNT_TEXT = "Post Count";
export const POST_COUNT_FIELD = "post_ct";
export const AI_NARRATIVE_SCORE_TEXT = "AI Narrative Score";
export const AI_NARRATIVES_SCORE_THRESHOLD = 0.85;
export const DESC_SORT_DIR = "DESC";
export const ASC_SORT_DIR = "ASC";

export const PLATFORMS = withFallbackValue(
  {
    // facebook: "facebook",
    // instagram: "instagram",
    all: "all",
    reddit: "reddit",
    weber_reddit_csv: "reddit",
    brandwatch_reddit_csv: "reddit",
    twitter: "twitter",
    deepweb: "deepweb",
    news: "news",
    webzio_social: "webzio_social", // Legacy
    social_media: "social_media",
    webzio_forums: "webzio_forums",
    discussions: "discussions",
    webzio_chat: "webzio_chat",
    chat: "chat",
    tiktok: "tiktok",
  },
  (platform_value) => platform_value
);

export const PLATFORM_BASE_URLS = withFallbackValue(
  {
    twitter: "https://twitter.com",
    reddit: "https://www.reddit.com",
    tiktok: "https://www.tiktok.com",
    deepweb: "",
  },
  ""
);

export const PLATFORM_USER_PATH = withFallbackValue(
  {
    twitter: "",
    reddit: "/user",
    tiktok: "",
    deepweb: "",
  },
  ""
);

export const PLATFORM_USER_PATH_PREFIX = withFallbackValue(
  {
    twitter: "",
    reddit: "",
    tiktok: "@",
    deepweb: "",
  },
  ""
);

export const LABELS_MAP = {
  bbmi: "Anomalous",
  BBMI: "Anomalous",
  pagerank: "Influencer",
  Pagerank: "Influencer",
  fear: "Fear",
  anger: "Angry",
  disgust: "Disgust",
  neutral: "Neutral",
  sadness: "Sadness",
  surprise: "Surprise",
  happiness: "Happiness",
};

export const SORT_OPTIONS = withFallbackValue(
  {
    [PLATFORMS.tiktok]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Shares: "share_ct",
      Likes: "like_ct",
      Risk: "harm",
      "Bot-like": "bot_score",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      "Original Post Count": "orig_post_ct",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.twitter]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Shares: "share_ct",
      Likes: "like_ct",
      Risk: "harm",
      "Bot-like": "bot_score",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      "Original Post Count": "orig_post_ct",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.reddit]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.deepweb]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Shares: "share_ct",
      Likes: "like_ct",
      Risk: "harm",
      "Bot-like": "bot_score",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      "Original Post Count": "orig_post_ct",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.news]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      // Engagement: "engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.webzio_social]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.social_media]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.webzio_chat]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.chat]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.webzio_forums]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
    [PLATFORMS.discussions]: {
      [POST_COUNT_TEXT]: POST_COUNT_FIELD,
      Engagement: "engagement",
      [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
      Risk: "harm",
      "Right Partisan": "partisanship",
      "Left Partisan": "partisanship",
      "Not Partisan": "partisan_nonpartisan",
      Anomalous: "bbmi",
      "Positive Sentiment": "positive_sentiment",
      "Neutral Sentiment": "neutral_sentiment",
      "Negative Sentiment": "negative_sentiment",
      Anger: "anger",
      Disgust: "disgust",
      Fear: "fear",
      Happiness: "happiness",
      Neutral: "neutral",
      Sadness: "sadness",
      Surprise: "surprise",
      Toxicity: "toxicity",
      [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
    },
  },
  {
    [POST_COUNT_TEXT]: POST_COUNT_FIELD,
    Engagement: "engagement",
    [HARMFUL_ENGAGEMENT_POSTS_LABEL]: "harmful_engagement",
    Shares: "share_ct",
    Likes: "like_ct",
    Risk: "harm",
    "Bot-like": "bot_score",
    "Right Partisan": "partisanship",
    "Left Partisan": "partisanship",
    "Not Partisan": "partisan_nonpartisan",
    Anomalous: "bbmi",
    "Positive Sentiment": "positive_sentiment",
    "Neutral Sentiment": "neutral_sentiment",
    "Negative Sentiment": "negative_sentiment",
    Anger: "anger",
    Disgust: "disgust",
    Fear: "fear",
    Happiness: "happiness",
    Neutral: "neutral",
    Sadness: "sadness",
    Surprise: "surprise",
    Toxicity: "toxicity",
    "Original Post Count": "orig_post_ct",
    [AI_NARRATIVE_SCORE_TEXT]: "narrative_score_avg",
  }
);

export const REVERSED_SORTS = ["Left Partisan"];
export const PARTISAN_SORT_AUTO_FILTERS = {
  "Left Partisan": ["left"],
  "Right Partisan": ["right"],
};

export const ENTITY_TYPES = {
  cluster: "cluster",
  post: "post",
  user: "user",
  url: "url",
  hashtag: "hashtag",
  domain: "domains",
};

export const ENTITY_TYPES_MAP = {
  users: ENTITY_TYPES.user,
  hashtags: ENTITY_TYPES.hashtag,
  urls: ENTITY_TYPES.url,
  postsInCluster: ENTITY_TYPES.post,
  clusters: ENTITY_TYPES.cluster,
  domain: ENTITY_TYPES.domain,
};

export const EXPLANATION_STYLES = {
  toxicity: {
    "font-weight": "bold",
    color: "#b135ff",
  },
  negative_emotion_anger: {
    "font-weight": "bold",
    color: "rgb(255, 162, 163)",
  },
  yellow: {
    "font-weight": "bold",
    color: "#F1BA7A",
  },
  white: {
    "font-weight": "bold",
    color: "#fff",
  },
  hoax: {
    "font-weight": "bold",
    color: "#e7394e",
  },
};
export const FIELD_NAMES = {
  known_hoax: "known_hoax",
};

export const USER_COLUMN_LABEL = "Author";
export const USER_LABEL = "Authors";

export const KEY_ENTITY_TABS = {
  users: USER_COLUMN_LABEL,
  hashtags: "Hashtags",
  urls: "Urls",
  subreddits: "Subreddits",
  domains: "Domains",
};

const randomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const REDDIT_CONFIG = {
  order: 2,
  name: "Reddit",
  value: PLATFORMS.reddit,
  isBeta: true,
  icon: (
    <Box
      component="i"
      className="icon-redditfilled"
      sx={{ color: "#EB1403", fontSize: "1.25rem" }}
    />
  ),
  color: "#EB1403",
  engagement_tooltip: "A cumulative measure of likes and shares on all posts.",
  engagement_tooltip_detailed:
    "A cumulative measure of all submission scores and comments on posts identified as high risk.",
  has_engagements: true,
  has_bot_like: false,
  has_likes: false,
  has_shares: false,
  has_comments: true,
  has_extended_data: true,
  has_open_author: true,
  had_verified_accounts: true,
  has_post_origin: true,
  has_quotes: false,
  has_custom_visualizations: false,
  has_impact_tab: false,
  has_domains: false,
  defaultXAxis: "orig_post_ct",
  post_text_field: "full_text",
  has_user_cohorts: true,
  hide_domain_in_tweet_footer: true,
};

export const PLATFORM_CONFIG = withFallbackValue(
  {
    [PLATFORMS.all]: {
      order: 1,
      name: "All",
      value: PLATFORMS.all,
      icon: (
        <Box
          component="i"
          className="icon-twitter"
          sx={{ color: "#056EFC", fontSize: "1.25rem" }}
        />
      ),
      color: "#056EFC",
      engagement_tooltip:
        "A cumulative measure of likes and shares on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of all likes and shares on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: true,
      has_likes: true,
      has_shares: true,
      has_comments: true,
      has_extended_data: true,
      has_open_author: true,
      had_verified_accounts: true,
      has_post_origin: true,
      has_quotes: true,
      has_anonymous_users: false,
      has_custom_visualizations: true,
      has_impact_tab: true,
      has_domains: true,
      defaultXAxis: "orig_post_ct",
      post_text_field: "text",
      has_user_cohorts: true,
      hide_domain_in_tweet_footer: true,
    },
    [PLATFORMS.tiktok]: {
      order: 1.5,
      name: "TikTok",
      value: PLATFORMS.tiktok,
      icon: null,
      color: "rgba(255, 59, 92, 1)",
      engagement_tooltip:
        "A cumulative measure of likes and shares on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of all likes and shares on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: true,
      has_likes: true,
      has_shares: true,
      has_comments: true,
      has_extended_data: true,
      has_open_author: true,
      had_verified_accounts: true,
      has_post_origin: true,
      has_quotes: true,
      has_anonymous_users: false,
      has_custom_visualizations: true,
      has_impact_tab: true,
      has_domains: false,
      defaultXAxis: "orig_post_ct",
      post_text_field: "text",
      has_user_cohorts: true,
      hide_domain_in_tweet_footer: true,
    },
    [PLATFORMS.twitter]: {
      order: 2,
      name: "Twitter",
      value: PLATFORMS.twitter,
      icon: (
        <Box
          component="i"
          className="icon-twitter"
          sx={{ color: "#056EFC", fontSize: "1.25rem" }}
        />
      ),
      color: "#056EFC",
      engagement_tooltip:
        "A cumulative measure of likes and shares on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of all likes and shares on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: true,
      has_likes: true,
      has_shares: true,
      has_comments: true,
      has_extended_data: true,
      has_open_author: true,
      had_verified_accounts: true,
      has_post_origin: true,
      has_quotes: true,
      has_anonymous_users: false,
      has_custom_visualizations: true,
      has_impact_tab: true,
      has_domains: false,
      defaultXAxis: "orig_post_ct",
      post_text_field: "text",
      has_user_cohorts: true,
      hide_domain_in_tweet_footer: true,
    },
    [PLATFORMS.weber_reddit_csv]: REDDIT_CONFIG,
    [PLATFORMS.brandwatch_reddit_csv]: REDDIT_CONFIG,
    [PLATFORMS.reddit]: REDDIT_CONFIG,
    [PLATFORMS.deepweb]: {
      order: 3,
      name: "Deepweb",
      value: PLATFORMS.deepweb,
      isBeta: true,
      icon: null,
      color: "#BDFC95",
      engagement_tooltip:
        "A cumulative measure of likes and shares on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of all likes and shares on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: true,
      has_likes: true,
      has_shares: true,
      has_comments: false,
      has_extended_data: true,
      has_open_author: true,
      had_verified_accounts: true,
      has_post_origin: true,
      has_quotes: false,
      has_anonymous_users: true,
      has_custom_visualizations: false,
      has_impact_tab: true,
      defaultXAxis: "orig_post_ct",
      post_text_field: "full_text",
      has_domains: true,
      has_user_cohorts: false,
    },
    [PLATFORMS.news]: {
      order: 4,
      name: "News",
      value: PLATFORMS.news,
      isBeta: true,
      icon: null,
      // color: "#8FE14A",
      engagement_tooltip:
        "A cumulative measure of likes and shares on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of all likes and shares on posts identified as high risk.",
      has_engagements: false, // Ticket: https://blackbirdai.atlassian.net/browse/WRB-1436
      has_bot_like: false,
      has_likes: false,
      has_shares: false,
      has_comments: false,
      has_extended_data: false,
      has_domains_stats: true,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: true,
      has_quotes: false,
      has_anonymous_users: false,
      has_custom_visualizations: false,
      has_impact_tab: false,
      defaultXAxis: "post_ct",
      has_domains: true,
      post_text_field: "full_text",
      has_user_cohorts: false,
    },
    [PLATFORMS.webzio_social]: {
      order: 5,
      name: "Alternative Social Media",
      value: PLATFORMS.webzio_social,
      isBeta: true,
      icon: null,
      engagement_tooltip: "The number of replies on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of replies on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: false,
      has_likes: false,
      has_shares: false,
      has_comments: false,
      has_extended_data: false,
      has_domains_stats: true,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: false,
      has_quotes: false,
      has_anonymous_users: false,
      has_impact_tab: false,
      defaultXAxis: "post_ct",
      has_domains: true,
      post_text_field: "full_text",
      has_user_cohorts: false,
    },
    [PLATFORMS.social_media]: {
      order: 5,
      name: "Alternative Social Media",
      value: PLATFORMS.social_media,
      isBeta: true,
      icon: null,
      engagement_tooltip: "The number of replies on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of replies on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: false,
      has_likes: false,
      has_shares: false,
      has_comments: false,
      has_extended_data: false,
      has_domains_stats: true,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: false,
      has_quotes: false,
      has_anonymous_users: false,
      has_impact_tab: false,
      defaultXAxis: "post_ct",
      has_domains: true,
      post_text_field: "full_text",
      has_user_cohorts: false,
    },
    [PLATFORMS.webzio_forums]: {
      order: 6,
      name: "Forums",
      value: PLATFORMS.webzio_forums,
      isBeta: true,
      engagement_tooltip: "The number of replies on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of replies on posts identified as high risk.",
      anonymous_posts_tooltip:
        "Anonymous Posts represents an aggregation of posts from author(s) within a dataset that cannot be verified as discrete users.",
      has_engagements: true,
      has_bot_like: false,
      has_likes: false,
      has_shares: false,
      has_comments: false,
      has_extended_data: false,
      has_domains_stats: true,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: false,
      has_quotes: false,
      has_anonymous_users: true,
      has_impact_tab: false,
      defaultXAxis: "post_ct",
      post_text_field: "full_text",
      has_domains: true,
      has_user_cohorts: false,
    },
    [PLATFORMS.discussions]: {
      order: 6,
      name: "Forums",
      value: PLATFORMS.discussions,
      isBeta: true,
      engagement_tooltip: "The number of replies on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of replies on posts identified as high risk.",
      anonymous_posts_tooltip:
        "Anonymous Posts represents an aggregation of posts from author(s) within a dataset that cannot be verified as discrete users.",
      has_engagements: true,
      has_bot_like: false,
      has_likes: false,
      has_shares: false,
      has_comments: false,
      has_extended_data: false,
      has_domains_stats: true,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: false,
      has_quotes: false,
      has_anonymous_users: true,
      has_impact_tab: false,
      defaultXAxis: "post_ct",
      post_text_field: "full_text",
      has_domains: true,
      has_user_cohorts: false,
    },
    [PLATFORMS.webzio_chat]: {
      order: 7,
      name: "Chat",
      value: PLATFORMS.webzio_chat,
      isBeta: true,
      engagement_tooltip: "The number of replies on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of replies on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: false,
      has_likes: false,
      has_shares: false,
      has_comments: false,
      has_extended_data: false,
      has_domains_stats: true,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: false,
      has_quotes: false,
      has_anonymous_users: false,
      has_impact_tab: false,
      defaultXAxis: "post_ct",
      post_text_field: "full_text",
      has_domains: true,
      has_user_cohorts: false,
    },
    [PLATFORMS.chat]: {
      order: 7,
      name: "Chat",
      value: PLATFORMS.chat,
      isBeta: true,
      engagement_tooltip: "The number of replies on all posts.",
      engagement_tooltip_detailed:
        "A cumulative measure of replies on posts identified as high risk.",
      has_engagements: true,
      has_bot_like: false,
      has_likes: false,
      has_shares: false,
      has_comments: false,
      has_extended_data: false,
      has_domains_stats: true,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: false,
      has_quotes: false,
      has_anonymous_users: false,
      has_impact_tab: false,
      defaultXAxis: "post_ct",
      post_text_field: "full_text",
      has_domains: true,
      has_user_cohorts: false,
    },
  },
  (name) => {
    // Default platform config
    return {
      order: 999,
      name: name,
      value: name,
      isBeta: true,
      icon: null,
      engagement_tooltip: "",
      engagement_tooltip_detailed: null,
      color: randomColor(),
      has_engagements: true,
      has_bot_like: true,
      has_likes: true,
      has_shares: true,
      has_comments: false,
      has_extended_data: true,
      has_domains_stats: false,
      has_domains: false,
      has_open_author: false,
      had_verified_accounts: false,
      has_post_origin: false,
      has_quotes: false,
      has_anonymous_users: false,
      has_custom_visualizations: false,
      has_impact_tab: false,
      defaultXAxis: "orig_post_ct",
      post_text_field: "text",
    };
  }
);

// Looks up configItem in any of platform configs
export const lookupPlatformConfigItem = (platform, configItem) => {
  if (Array.isArray(platform)) {
    const configs = platform.map((p) => PLATFORM_CONFIG[p]);
    const found = configs.find((c) => c[configItem]);
    return found ? found[configItem] : null;
  }

  const platformConfig = PLATFORM_CONFIG[platform];
  return platformConfig?.[configItem];
};

export const getSelectedPlatformsAsList = (platform, platforms) => {
  if (platform === PLATFORMS.all) {
    return (platforms || []).map((p) => p.value);
  }

  return platform || [];
};

export const sortPlatforms = (platformA, platformB) => {
  return PLATFORM_CONFIG[platformA]?.order - PLATFORM_CONFIG[platformB]?.order;
};

export const isPlatform = (name) => {
  return Object.keys(PLATFORMS).includes(name);
};

export const QUERY_KEYS = {
  languages: "languages",
  cohorts: "cohorts",
  user: "user",
  project: "project",
  summary: "summary",
  posts: "posts",
  users: "users",
  hashtags: "hashtags",
  urls: "urls",
  activity: "activity",
  report_activity: "report_activity",
  concepts: "concepts",
  impact: "impact",
  impactSummary: "impactSummary",
  postsInCluster: "postsInCluster",
  clusters: "clusters",
  hoax: "hoax",
  subreddits: "subreddits",
  field_entries: "field_entries",
  group_labels: "group_labels",
  ai_narratives: "ai_narratives",
  domains: "domain",
  projects: "projects",
  parents: "parents",
  narratives: "narratives",
  platforms: "platforms",
  custom_endpoint: "custom_endpoint",
  model_versions: "model_versions",
  data_connectors: "data_connectors",
};

export const LEAD_CATEGORIES = {
  PHRASE: "Phrase",
  HASHTAG: "Hashtag",
};
export const INVOLVED_PARTIES = {
  "USER MENTIONS": "User Mentions",
  PERSON: "Person",
  ORGANIZATION: "Organization",
  TITLE: "Title",
};
export const CONTEXT_DRIVERS = {
  IDEOLOGY: "Ideology",
  RELIGION: "Religion",
  // "CAUSE OF DEATH": "Cause of Death",
  // "CRIMINAL CHARGE": "Criminal Charge",
  MISC: "Misc",
  URL: "Urls",
};
export const PLACE = {
  NATIONALITY: "Nationality",
  LOCATION: "Location",
};

export const VISUALIZE_CONCEPTS = {
  ...INVOLVED_PARTIES,
  ...LEAD_CATEGORIES,
  ...CONTEXT_DRIVERS,
  ...PLACE,
};

export const CONCEPTS = [
  "PHRASE",
  "HASHTAG",
  "USER MENTIONS",
  "PERSON",
  "ORGANIZATION",
  "TITLE",
  "IDEOLOGY",
  "RELIGION",
  "CAUSE OF DEATH",
  "CRIMINAL CHARGE",
  "MISC",
  "URL",
  "NATIONALITY",
  "LOCATION",
  "DURATION",
  "DATE",
  "TIME",
  "SET",
];

export const tagMap = {
  Phrase: "Phrase",
  Hashtag: "Hashtag",
  Person: "Person",
  "User Mentions": "User Mentions",
  "Criminal Charge": "Criminal Charge",
  "Cause of Dealth": "CoD",
  "Cause of Death": "CoD",
  Location: "LOC",
  Organization: "ORG",
  Title: "Title",
  Duration: "Duration",
  Ideology: "Ideology",
  Nationality: "Nationality",
  Religion: "Religion",
  Misc: "Misc",
  Date: "Date",
  Time: "Time",
  Set: "Set",
  URLs: "URL",
  Url: "URL",
  Urls: "URL",
};

export const prod_env = "production";

export const DEFAULT_INCLUDE_QUERY = [
  {
    field: "is_manipulated",
    operator: "and",
    value: true,
    min: 0,
    max: 100,
  },
];

export const FILTER_TARGETS = {
  narrative_builder: "narrative_builder",
  overview: "overview",
  analyze: "analyze",
};

export const LANGUAGES = {
  ...langcodes,
  unknown: {
    name: "Unknown",
    emoji: "❓",
  },
};

export const LANGUAGES_LIMIT = 0.002;

export const EXCLUDED_COHORTS = ["DescriptiveContent"];

export const POST_CHAR_LIMIT = 400;

export const SAVED_METRICS_FIELD = "VISIBLE_METRICS";
export const SAVED_METRICS_FIELD_ORDER = "VISIBLE_METRICS_ORDER";
export const SAVED_PAGE_SIZE_FIELD = "PAGE_SIZE";

export const SAVED_COLUMNS_FIELD = "VISIBLE_COLUMNS";

const aiNarOptions = new Map(
  [
    {
      value: "orig_post_ct",
      label: "Original posts",
      sortField: "orig_post_ct",
      type: "count",
    },
    {
      value: "post_ct",
      label: POST_COUNT_TEXT,
      type: "count",
      sortField: "post_ct",
    },
    {
      value: "engagement",
      label: "Engagement",
      type: "count",
      sortField: "engagement",
    },
    {
      value: "harmful_engagement",
      label: "Risky Engagements",
      type: "count",
      sortField: "harmful_engagement",
    },
    {
      value: "share_ct",
      label: "Shares",
      type: "count",
      sortField: "share_ct",
    },
    { value: "like_ct", label: "Likes", type: "count", sortField: "like_ct" },
    { value: "is_harmful", label: "Risk", sortField: "harm" },
    { value: "is_manipulated", label: "Anomalous", sortField: "bbmi" },
    { value: "is_bot_avg", label: "Bot-like", sortField: "bot_score" },
    { value: "is_toxic_avg", label: "Toxic", sortField: "toxicity" },
    // { value: "is_influential", label: "Influential", sortField: "is_influential"  },
    {
      value: "is_partisan_right_avg",
      label: "Right Partisan",
      sortField: "partisanship",
    },
    {
      value: "is_partisan_left_avg",
      label: "Left Partisan",
      sortField: "partisanship",
    },
    {
      value: "is_non_partisan_avg",
      label: "Not Partisan",
      sortField: "partisan_nonpartisan",
    },
    {
      value: "is_positive_avg",
      label: "Positive Sentiment",
      sortField: "positive_sentiment",
    },
    {
      value: "is_neutral_sentiment_avg",
      label: "Neutral Sentiment",
      sortField: "neutral_sentiment",
    },
    {
      value: "is_negative_avg",
      label: "Negative Sentiment",
      sortField: "negative_sentiment",
    },
    { value: "is_anger_avg", label: "Anger", sortField: "anger" },
    { value: "is_disgust_avg", label: "Disgust", sortField: "disgust" },
    { value: "is_fear_avg", label: "Fear", sortField: "fear" },
    { value: "is_happiness_avg", label: "Happiness", sortField: "happiness" },
    { value: "is_neutral_emotion_avg", label: "Neutral", sortField: "neutral" },
    { value: "is_sadness_avg", label: "Sadness", sortField: "sadness" },
    { value: "is_surprise_avg", label: "Surprise", sortField: "surprise" },
    {
      value: "narrative_score_avg",
      label: AI_NARRATIVE_SCORE_TEXT,
      sortField: "narrative_score_avg",
    },
  ].map((d) => [d.value, d])
);

const makeGroup = (options, groupName) => {
  return options.map((d, i) => ({ ...d, groupName, showGroup: i === 0 }));
};

const volume_metrics = makeGroup(
  [
    aiNarOptions.get("orig_post_ct"),
    aiNarOptions.get("post_ct"),
    aiNarOptions.get("engagement"),
    aiNarOptions.get("harmful_engagement"),
    aiNarOptions.get("share_ct"),
    aiNarOptions.get("like_ct"),
  ],
  "VOLUME"
);

const risk_signals_metrics = makeGroup(
  [
    aiNarOptions.get("is_harmful"),
    aiNarOptions.get("is_manipulated"),
    aiNarOptions.get("is_bot_avg"),
    aiNarOptions.get("is_toxic_avg"),
    // aiNarOptions.get("is_influential"),
    aiNarOptions.get("is_negative_avg"),
  ],
  "RISK SIGNALS"
);

const partisanship_metrics = makeGroup(
  [
    aiNarOptions.get("is_partisan_right_avg"),
    aiNarOptions.get("is_partisan_left_avg"),
    aiNarOptions.get("is_non_partisan_avg"),
  ],
  "PARTISANSHIP"
);

const sentiment_metrics = makeGroup(
  [
    aiNarOptions.get("is_positive_avg"),
    aiNarOptions.get("is_negative_avg"),
    aiNarOptions.get("is_neutral_sentiment_avg"),
  ],
  "SENTIMENT"
);

const relevance_metrics = makeGroup(
  [aiNarOptions.get("narrative_score_avg")],
  "RELEVANCE"
);

export const getAiNarMetrics = ({ cohorts, topics, emotions, platform }) => {
  const emotion_metrics = emotions.map((d, i) => ({
    groupName: "EMOTION",
    showGroup: i === 0,
    value: "is_" + d.fieldName + "_avg",
    label: d.displayName,
  }));
  let local_risk_signals_metrics = risk_signals_metrics;
  let local_volume_metrics = volume_metrics;
  if (
    platform?.includes(PLATFORMS.news) ||
    platform?.includes(PLATFORMS.reddit) ||
    platform?.some((p) => p.includes("webz"))
  ) {
    if (platform?.includes(PLATFORMS.news)) {
      local_volume_metrics = makeGroup([aiNarOptions.get("post_ct")]);
    }

    // Hide engagement if only news platform
    if (platform?.includes(PLATFORMS.news) && platform.length === 1) {
      local_volume_metrics = makeGroup([aiNarOptions.get("post_ct")]);
    } else {
      local_volume_metrics = makeGroup([
        aiNarOptions.get("post_ct"),
        aiNarOptions.get("engagement"),
        aiNarOptions.get("harmful_engagement"),
      ]);
    }

    local_risk_signals_metrics = local_risk_signals_metrics.filter(
      (risk_metric) => risk_metric.value !== "is_bot_avg"
    );
  }

  const cohort_metrics = lookupPlatformConfigItem(platform, "has_user_cohorts")
    ? cohorts.map((d, i) => ({
        groupName: "COHORTS",
        showGroup: i === 0,
        isUserCohort: true,
        value: d.fullFieldName, // count of cohorts
        label: d.displayName,
      }))
    : [];

  const topic_metrics = topics.map((d, i) => ({
    groupName: "TOPICS",
    showGroup: i === 0,
    isTopic: true,
    value: d.fullFieldName, // count of topics
    label: d.displayName,
  }));

  return {
    all_options: new Map([
      ...aiNarOptions,
      ...emotion_metrics.map((d) => [d.value, d]),
      ...topic_metrics.map((d) => [d.value, d]),
      ...cohort_metrics.map((d) => [d.value, d]),
    ]),
    x_axis: [
      ...local_volume_metrics,
      ...local_risk_signals_metrics,
      ...partisanship_metrics,
      ...sentiment_metrics,
      ...emotion_metrics,
      ...topic_metrics,
      ...cohort_metrics,
    ],
    y_axis: [
      ...local_risk_signals_metrics,
      ...local_volume_metrics,
      ...partisanship_metrics,
      ...sentiment_metrics,
      ...emotion_metrics,
      ...topic_metrics,
      ...cohort_metrics,
    ],
    node_size: [
      ...local_volume_metrics,
      ...local_risk_signals_metrics,
      ...partisanship_metrics.filter((d) => d.value !== "is_non_partisan_avg"),
      ...sentiment_metrics.filter(
        (d) => d.value !== "is_neutral_sentiment_avg"
      ),
      ...emotion_metrics,
      ...topic_metrics,
      ...cohort_metrics,
    ],
    node_color: [
      ...local_risk_signals_metrics,
      ...local_volume_metrics,
      ...partisanship_metrics.filter((d) => d.value !== "is_non_partisan_avg"),
      ...sentiment_metrics.filter(
        (d) => d.value !== "is_neutral_sentiment_avg"
      ),
      ...emotion_metrics,
      ...topic_metrics,
      ...cohort_metrics,
    ],
    limit_by: [
      ...local_volume_metrics,
      ...local_risk_signals_metrics,
      ...partisanship_metrics,
      ...sentiment_metrics,
      ...emotion_metrics,
      ...relevance_metrics,
    ],
  };
};

export const getUserPrefixByPlatform = withFallbackValue(
  {
    [PLATFORMS.reddit]: "u/",
    [PLATFORMS.twitter]: "@",
    [PLATFORMS.webzio_social]: "@",
    [PLATFORMS.social_media]: "@",
    [PLATFORMS.webzio_forums]: "@",
    [PLATFORMS.discussions]: "@",
    [PLATFORMS.webzio_chat]: "@",
    [PLATFORMS.chat]: "@",
    [PLATFORMS.tiktok]: "@",
  },
  ""
);

export const AUTHORS_PER_GROUP_LIMIT = 3000;
export const AUTHOR_GROUPS_LIMIT = 15;
export const METRIC_SERIES_LIMIT = 10;

export const STATS_ROW_LIMITS = {
  topics: 8,
  authors: 7,
  emotions: 8,
};

export const ANALYZE_ENTITY_LIMITS = {
  topics: 5,
  userGroups: 5,
  userCohorts: 5,
};

export const JOB_ERRORS_BY_CODE = {
  101: "Cannot identify delimiter",
  102: "File encoding not supported",
  103: 'Cannot identify URL column (could also be "Required fields missing")',
  104: "URL data missing",
  105: "File size too big",
  201: "Ran out of credits",
  202: "No data in export configuration",
  203: "No parsable data found in file or query",
  204: "Topic was deleted. Try disabling auto updates",
  301: "No parsable data found in file or query",
  302: "Unauthorized access",
  400: "Configuration error",
  401: "No data found",
  404: "No parsable data could be found",
  405: "No parsable data found in file or query",
  501: "Unauthorized access",
};

export const ANONYMOUS_TOOLTIP =
  "@Anonymous represents an aggregation of author(s) within a dataset that cannot be verified as discrete users with unique IDs";

export const LEARN_MORE_DATA_RECEIPT =
  "https://blackbirdai.zendesk.com/hc/en-us/articles/17738896063377-Managing-Projects-Data-Receipt";
export const TIMEZONE_TOOLTIP =
  "To set your timezone, visit 'Account' > 'Edit Profile', then select your preferred timezone from the list";

export const SUPPORT_EMAIL = "support@blackbird.ai";
export const SALES_EMAIL = "sales@blackbird.ai";
