import { authenticateWithSSOCode, refreshAccessToken } from "api/endpoints/ssoApi";
import { JWT_REFRESH_TOKEN_FIELD, JWT_TOKEN_FIELD, SSO_USER_FIELD } from "./constants";

// Constants for SSO user and token refreshing status
const SSO_USER_FIELD_VALUE = "true";
const SSO_REFRESHING_TOKEN_STATUS_FIELD = "sso_refreshing_token";

/**
 * Checks if the user is an SSO user
 * @returns {boolean} - Returns true if the user is an SSO user, false otherwise
 */
export const isUserSSO = () => {
    return localStorage.getItem(SSO_USER_FIELD) === SSO_USER_FIELD_VALUE;
}

/**
 * Handles the SSO authentication
 * @param code {string} - The SSO code used for authentication
 */
export const handleSSOAuthentication = async (code: string) => {
    const res = await authenticateWithSSOCode(code);
    handleSSOAuthenticationResponse(res);
}

/**
 * Refreshes the SSO access token
 * @returns {boolean} - Returns true if the token refresh is successful, false otherwise
 */
export const refreshSSOAccessToken = async (): Promise<boolean> => {
    // Check if a token refresh is already in progress
    if(localStorage.getItem(SSO_REFRESHING_TOKEN_STATUS_FIELD)) {
        return true;
    }
    const refreshToken = localStorage.getItem(JWT_REFRESH_TOKEN_FIELD);
    if(!refreshToken) {
        console.error("No refresh token found");
        return false;
    }
    // Set the status to indicate token refresh is in progress
    localStorage.setItem(SSO_REFRESHING_TOKEN_STATUS_FIELD, "true");
    console.log("Refreshing SSO access token");
    try {
        const res = await refreshAccessToken(refreshToken);
        handleSSOAuthenticationResponse(res);
        return true;
    } catch (error) {
        console.error("Error refreshing SSO access token", error);
        return false;
    } finally {
        // Remove the status indicating token refresh is complete
        localStorage.removeItem(SSO_REFRESHING_TOKEN_STATUS_FIELD);
    }
}

/**
 * Handles the response from the SSO authentication
 * @param res {any} - The response object from the authentication request
 */
export const handleSSOAuthenticationResponse = (res: any) => {
    const {data} = res;
    const {access_token, refresh_token} = data;

    // Save the access token to local storage
    localStorage.setItem(JWT_TOKEN_FIELD, access_token);
    if(refresh_token) {
        // Save the refresh token to local storage if it exists
        localStorage.setItem(JWT_REFRESH_TOKEN_FIELD, refresh_token);
    }
    // Mark the user as an SSO user
    localStorage.setItem(SSO_USER_FIELD, SSO_USER_FIELD_VALUE);
    // Remove the token refreshing status
    localStorage.removeItem(SSO_REFRESHING_TOKEN_STATUS_FIELD);
}