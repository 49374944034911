import { TextField } from "@mui/material";

export default function InputValidate({
  value,
  emptyWarning = "",
  existsWarning = "",
  placeholder = "",
  label,
  exists,
  showError, // force to show error
  disabled,
  onChange,
  maxLength,
  fieldProps = {},
}) {
  const helperText =
    showError && value === "" ? emptyWarning : exists ? existsWarning : null;

  return (
    <TextField
      disabled={disabled}
      value={value}
      error={!!helperText}
      helperText={helperText}
      variant="outlined"
      size="medium"
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      inputProps={{ maxLength }}
      fullWidth
      {...fieldProps}
    />
  );
}
