import axios from "axios";
import * as qs from "qs";
import { JWT_REFRESH_TOKEN_FIELD, JWT_TOKEN_FIELD } from "utils/constants";

const REACT_APP_COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN || 'https://blackbird-ai-staging.auth.us-west-2.amazoncognito.com';
const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || '1chm6taqqs0ok496ep85m9vipe';
const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI || 'https://staging.blackbirdaitools.com/sso_callback';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem(JWT_REFRESH_TOKEN_FIELD);
        const response = await axios.post(`${REACT_APP_COGNITO_DOMAIN}/oauth2/token`, qs.stringify({
          'grant_type': 'refresh_token',
          'client_id': REACT_APP_SSO_CLIENT_ID,
          'refresh_token': refreshToken
        }), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });

        const { access_token, refresh_token: newRefreshToken } = response.data;
        localStorage.setItem(JWT_TOKEN_FIELD, access_token);
        localStorage.setItem(JWT_REFRESH_TOKEN_FIELD, newRefreshToken);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem(JWT_TOKEN_FIELD);
        localStorage.removeItem(JWT_REFRESH_TOKEN_FIELD);
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

/**
 * Authenticates with SSO using the code from the callback
 * @param {*} code 
 * @returns 
 */
export const authenticateWithSSOCode = async (code) => {
    let data = qs.stringify({
        'grant_type': 'authorization_code',
        'client_id': REACT_APP_SSO_CLIENT_ID,
        'code': code,
        'redirect_uri': REACT_APP_SSO_REDIRECT_URI
    });

    return handleCognitoRequest(data);
};

/**
 * Refreshes the SSO access token
 * @returns 
 */
export const refreshAccessToken = async (refreshToken) => {
    let data = qs.stringify({
        'grant_type': 'refresh_token',
        'client_id': REACT_APP_SSO_CLIENT_ID,
        'refresh_token': refreshToken
    });

    return handleCognitoRequest(data);
};

const handleCognitoRequest = async (data) => {
    let config = {
        method: 'post',
        url: `${REACT_APP_COGNITO_DOMAIN}/oauth2/token`,
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
    };

    const res = await axiosInstance.request(config);
    return res;
}