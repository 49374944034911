import toast, { Toast } from "react-hot-toast";
import { ErrorCode } from "api/errors/errors";
import ErrorSnack from "components/Error/ErrorSnack";
import { ReactComponent as CriticalError } from "icons/Error/CriticalError.svg";
import { ReactComponent as NetworkError } from "icons/Error/NetworkError.svg";
import { isUserSSO, refreshSSOAccessToken } from "utils/sso";
import { handleLogout } from "utils/auth";

interface ErrorConfiguration {
  icon: React.FC;
  color: string;
  iconBackgroundColor: string;
  actionBackground: string;
  backgroundColor: string;
  borderTop: string;
  actionTextColor: string;
}

export const ERROR_CONFIG: { [key: string]: ErrorConfiguration } = {
  network: {
    icon: NetworkError,
    color: "#E9E4FF",
    iconBackgroundColor: "#2E2A40",
    actionBackground: "rgba(41, 38, 53, 0.6)",
    borderTop: "none",
    actionTextColor: "rgba(255, 255, 255, 0.66)",
    backgroundColor: "#1D1A2C",
  },
  critical: {
    icon: CriticalError,
    color: "#EB6868",
    iconBackgroundColor: "rgba(190, 33, 33, 0.16)",
    actionBackground: "#241a28",
    borderTop: "1px solid rgb(52, 40, 54)",
    actionTextColor: "white",
    backgroundColor: "#221725",
  },
};

const SIGN_IN = "Sign In";
const REFRESH = "Refresh";

const toastConfig = {
  id: "error-toast",
  duration: 5000,
  style: {
    background: "transparent",
  },
};

const showToast = (
  t: Toast,
  message: string,
  subtitle: string,
  styleConfig,
  actionText: string
) => {
  const handleClick = () => {
    toast.dismiss(t.id);
    if (actionText === SIGN_IN) {
      handleLogout();
    } else if (actionText === REFRESH) {
      window.location.reload();
    }
  };

  return (
    <ErrorSnack
      title={message}
      subtitle={subtitle}
      icon={styleConfig.icon}
      iconColor={styleConfig.color}
      iconBackground={styleConfig.iconBackgroundColor}
      actionBackground={styleConfig.actionBackground}
      backgroundColor={styleConfig.backgroundColor}
      actionTextColor={styleConfig.actionTextColor}
      borderTop={styleConfig.borderTop}
      actionText={actionText}
      actionUrl={actionText === SIGN_IN ? "/login" : undefined}
      onActionClick={handleClick}
    />
  );
};

export const showError = (
  message: string,
  subtitle: string,
  actionText: string,
  styleConfig: ErrorConfiguration = ERROR_CONFIG.critical
) => {
  toast(
    (t) => showToast(t, message, subtitle, styleConfig, actionText),
    toastConfig
  );
};

export const handleErrorByCode = (code: ErrorCode, message: string) => {
  switch (code) {
    case ErrorCode.PERMISSION_DENIED:
    case ErrorCode.UNAUTHENTICATED:
      // If the user is an SSO user, refresh the access token
      if (isUserSSO()) {
        refreshSSOAccessToken().then((success) => {
          if (!success) {
            showError(
              "Your session has expired",
              "Please sign back in.",
              SIGN_IN
            );
          }
        });
      } else {
        showError("Your session has expired", "Please sign back in.", SIGN_IN);
      }
      break;

    case ErrorCode.UNAVAILABLE:
      showError(
        "A network error occurred.",
        "Please refresh the page",
        REFRESH,
        ERROR_CONFIG.network
      );
      break;

    // Silently handle internal errors
    // case ErrorCode.INTERNAL:
    //   showError("An internal error occurred", "Please refresh the page", refreshPage, "Refresh", ERROR_CONFIG.network);
    //   break;

    case ErrorCode.RESOURCE_EXHAUSTED:
      showError(
        "Slow down Tiger!",
        "This API is rate limited. Please try again after a minute.",
        ""
      );
      break;

    default:
      console.error(`Unhandled error: ${code} ${message}`);
      break;
  }
};
