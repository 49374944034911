import { useState } from "react";
import { Redirect, useHistory, useLocation, Link } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
// import { useFlags } from "launchdarkly-react-client-sdk";

import { login, checkIfSSO } from "api/endpoints/AnalyticsApi";
import LoginBackground from "pages/Authentication/LoginBackground";
import InputValidate from "components/UI/InputValidate";
import Message from "./Message";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { JWT_TOKEN_FIELD, NARRATIVE_FEED_KEY } from "utils/constants";
import useTitle from "hooks/useTitle";
import { trackUserEvent } from "api/monitoring/userEventTrackingApi";

const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID;
const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI;
const REACT_APP_COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;

const ACCOUNT_TYPE = {
  SSO: "sso",
  STRAPI: "strapi",
};

function App() {
  // Currently disabled as SSO will work only if the user is setup with SSO
  // const flags = useFlags();
  // const {sso} = flags;
  const title = useTitle();

  // Get the error from the query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get("error");

  const [identifier, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(error);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState(null);

  const { dispatch } = useAppContext();

  const history = useHistory();

  const checkIfSSOUser = async (identifier) => {
    try {
      // Get domain from email safely
      const domain = identifier.split("@")[1];
      const { body } = await checkIfSSO(domain);
      return { isSSOUser: body.isSSOUser, idpIdentifier: domain };
    } catch (error) {
      console.error("Error checking SSO user status:", error);
      setLoginError("Error checking SSO user status");
      return false;
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    // Check if the user is an SSO user or not
    if (accountType === null) {
      // Check if the user is an SSO user
      const { isSSOUser, idpIdentifier } = await checkIfSSOUser(identifier);
      if (isSSOUser) {
        trackUserEvent("SSO Login", {
          type: "SSO Login",
          value: "SSO Login",
          path: "/login",
        });
        setAccountType(ACCOUNT_TYPE.SSO);
        handleSSOLogin(idpIdentifier);
      } else {
        trackUserEvent("Strapi Login", {
          type: "Strapi Login",
          value: "Strapi Login",
          path: "/login",
        });
        setAccountType(ACCOUNT_TYPE.STRAPI);
      }
    }

    if (accountType === ACCOUNT_TYPE.STRAPI) {
      try {
        // Reset error popups
        toast.dismiss();

        // Ensure context is reset
        dispatch({ type: ACTIONS.LOGOUT });

        const res = await login(identifier, password);
        if (res) {
          localStorage.setItem(
            NARRATIVE_FEED_KEY,
            !!res?.user?.organization?.has_narrative_feed
          );
          localStorage.setItem(JWT_TOKEN_FIELD, res.jwt);
          setLoggedIn(true);
          setLoading(false);
          history.push("/dashboard");
        } else {
          // Extra handling in case there is no JWT in response
          setLoading(false);
          setLoggedIn(false);
          setLoginError("Error logging in. Please try again.");
        }
      } catch (error) {
        setLoading(false);
        setLoginError(
          "Invalid username or password. Please check your credentials."
        );
      }
    }
  };

  const forgotPassword = () => {
    trackUserEvent("Forgot Password Clicked", {
      type: "Forgot Password Clicked",
      value: "Forgot Password Clicked",
      path: "/login",
    });
    history.push("/forgotpassword");
  };

  if (loggedIn) {
    return <Redirect to="/dashboard" />;
  }

  const handleSSOLogin = (idpIdentifier) => {
    // Validate the user's email
    if (!identifier) {
      setLoginError("Please enter your email");
      return;
    }

    // Redirect to a SSO login page in Cognito// Construct the SSO login URL
    const ssoLoginUrl =
      `${REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?` +
      `client_id=${REACT_APP_SSO_CLIENT_ID}&` +
      `response_type=code&` +
      `scope=email+openid+profile+constellation/write&` +
      `redirect_uri=${REACT_APP_SSO_REDIRECT_URI}&` +
      `idp_identifier=${encodeURIComponent(idpIdentifier)}&` +
      `login_hint=${encodeURIComponent(identifier)}`;

    // Redirect to the SSO login page in Cognito
    window.location.href = ssoLoginUrl;
  };

  return (
    <LoginBackground>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Typography
        variant="h3"
        mb={2}
        sx={{ fontWeight: 600, textAlign: "center", marginBottom: 4 }}
      >
        Sign in to Constellation
      </Typography>

      <Box sx={{ pb: 2 }}>
        <form autoComplete="true" onSubmit={onSubmit}>
          <Box sx={{ my: 2 }}>
            <Typography
              variant="label1"
              component="div"
              sx={{ mb: 1, color: "text.light" }}
              data-testid="login-username-label"
            >
              Username or E-mail
            </Typography>
            <InputValidate
              value={identifier}
              onChange={(event) => setUsername(event.target.value)}
              fieldProps={{
                autoFocus: true,
                disabled: loading,
                id: "SignInUsername",
                "data-testid": "login-username-input",
              }}
            />
          </Box>

          {accountType === ACCOUNT_TYPE.STRAPI && (
            <Box sx={{ my: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="label1"
                  component="div"
                  sx={{ mb: 1, color: "text.light" }}
                  data-tesid="login-password-label"
                >
                  Password
                </Typography>
                <Typography variant="label2" component="div" sx={{ mb: 1 }}>
                  <Button
                    variant="text"
                    LinkComponent={Link}
                    to="/forgotpassword"
                    onClick={forgotPassword}
                    sx={{
                      p: 0,
                      textTransform: "none",
                      fontWeight: 400,
                      color: "text.green",
                      minWidth: 'auto', // Remove minimum width
                      '&:hover': {
                        background: 'none',
                        textDecoration: 'underline'
                      }
                    }}
                    id="ForgotPasswordButton"
                    data-testid="forgot-password-button"
                  >
                    Forgot password?
                  </Button>
                </Typography>
              </Box>
              <InputValidate
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fieldProps={{
                  type: "password",
                  disabled: loading,
                  id: "SignInPassword",
                  "data-testid": "login-password-input",
                }}
              />
            </Box>
          )}

          {loginError && (
            <Message
              type="error"
              message={loginError}
              alignment="left"
              data-testid="login-error-message"
            />
          )}

          <Button
            data-testid="login-button"
            type={"submit"}
            size="medium"
            variant={"contained"}
            fullWidth
            disabled={loading}
            id="SignInButton"
          >
            {loading ? (
              <CircularProgress color="secondary" size={24} thickness={3} />
            ) : (
              <>{accountType === null ? "Continue" : "Sign in"}</>
            )}
          </Button>
        </form>
      </Box>
    </LoginBackground>
  );
}

export default App;
