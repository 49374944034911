export const SSO_ERROR_CODES = {
    SEAT_LIMIT: "Your organization has reached its user limit. Contact your admin for access.",
    DEFAULT: "An SSO authentication error occurred. Contact your admin for help."
}

// Uses keywords from the errors returned by the SSO provider
// to determine the type of error and return a message
export const getSSOErrorMessage = (error) => {
    const searchError = error.toLowerCase();
    // Finds the error code in the error message
    // https://github.com/blackbirdai-team/blackbird-cms/blob/5a9f5f6026195c29c74138ed0a7d6460a6042512/src/extensions/users-permissions/content-types/user/lifecycles.js#L40
    if (searchError.includes("seat") || searchError.includes("limit")) {
        return SSO_ERROR_CODES.SEAT_LIMIT;
    }
    return SSO_ERROR_CODES.DEFAULT;
};