import backgroundImage from "images/login-bg.jpg";
import { Grid, Box, useTheme } from "@mui/material";
import BlackbirdTextLogo from "components/blackbird-text-logo";

const useStyles = ({theme}) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#191826",
    backgroundPosition: "center bottom",
  },
  outerContainer: {
    padding: theme.spacing(4, 4, 4, 4),
  },
  logo: {
    position: "absolute",
    top: 20,
    left: 20,
  },
  text: {
    color: "#E8E9F3",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  login: {
    padding: theme.spacing(6, 4, 6, 4),
    backgroundColor: "rgba(34, 34, 55, 0.3)",
    border: "1px solid #292942",
    width: "100%",
    borderRadius: "12px",
    backdropFilter: "blur(10px)",
    maxWidth: "410px",
  },
  credential: {
    marginBottom: theme.spacing(1),
  },
  forgotPassword: {
    marginTop: "1rem",
  },
  header: {
    marginTop: 0,
  },
  left: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      marginBottom: 0,
    },
  },
  right: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blackbirdLogoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  texts: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
  },
});

function LoginBackground({
  children,
  title = "Constellation Dashboard",
  subtitle,
}) {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <Box sx={styles.root}>
      <Box sx={styles.logo}>
        <BlackbirdTextLogo login={true} />
      </Box>
      <Grid
        sx={styles.outerContainer}
        container
        direction="row"
        justifyContent="space-around"
        spacing={0}
      >
          {children && <Grid sx={styles.login}>{children}</Grid>}
      </Grid>
    </Box>
  );
}

export default LoginBackground;
