import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { handleLogout } from "utils/auth";

export default function ProjectsNotFound() {
  return (
    <Box sx={{ textAlign: "center", p: 3 }}>
      <Typography variant={"h6"} gutterBottom>
        Projects not found. Try a different account or contact administrator.
      </Typography>
      <Button
        LinkComponent={Link}
        to="/login"
        data-testid="login-button"
        onClick={handleLogout}
      >
        Login
      </Button>
    </Box>
  );
}
