import { PLATFORM_CONFIG } from "./constants";

// Take the request object and return a new object with the platform added unless it's "All"
export const preparePlatformForApi = (req) => {
  if (!req?.platform) return req;
  const { platform } = req;
  const reqCopy = { ...req };
  delete reqCopy.platform;
  return {
    ...reqCopy,
    ...(platform !== PLATFORM_CONFIG.all.value && { platform }),
  };
};

/**
 * Parse the platformId from the URL into an array of valid platform IDs.
 * 
 * @param {string} platformId - The platform ID(s) from the URL. It can be a single platform ID, 
 *                               a comma-separated list, or a special value (e.g., 'all').
 * @param {Array<{ name: string }>} availablePlatforms - A list of available platforms with their names.
 * @returns {Array<string>|string} - Returns an array of valid platform IDs if the input is valid.
 *                                   If invalid or matches the special value for "all", returns 'all'.
 * 
 * @example
 * const platformId = "twitter,reddit";
 * const availablePlatforms = [{ name: 'twitter' }, { name: 'reddit' }];
 * parsePlatformUrlID(platformId, availablePlatforms);
 * // Output: ['twitter', 'reddit']
 * 
 * @example
 * const platformId = "unknown";
 * const availablePlatforms = [{ name: 'twitter' }, { name: 'reddit' }];
 * parsePlatformUrlID(platformId, availablePlatforms);
 * // Output: 'all'
 */
export const parsePlatformUrlID = (platformId, availablePlatforms) => {
  if (!platformId || platformId === PLATFORM_CONFIG.all.value) {
    // Return the default 'all' value if no platformId is provided or it matches the 'all' value
    return PLATFORM_CONFIG.all.value;
  }

  // Create a lookup set of valid platform names
  const availablePlatformsLookup = new Set((availablePlatforms || []).map((d) => d.name));

  // Parse the platformId into an array (handle comma-separated or single values)
  const parsed = platformId.includes(",")
    ? platformId.split(",")
    : [platformId];

  // Filter out invalid platform names
  const mapped = parsed.filter((platformName) =>
    availablePlatformsLookup.has(platformName)
  );

  // If no valid platforms are found, return the default 'all' value
  if (!mapped.length) {
    return PLATFORM_CONFIG.all.value;
  }

  // Return the array of valid platform names
  return mapped;
};

// Check if its an all platform or if it contains one of the platforms
export const isPlatformAccepted = (platforms, acceptedPlatforms) => {
  if (!platforms) return false;
  if (platforms.length === 0) return false;
  // If the platform is not an array, convert it to an array
  if (!Array.isArray(platforms)) platforms = [platforms];
  return (
    platforms[0] === PLATFORM_CONFIG.all.value ||
    platforms.includes(acceptedPlatforms)
  );
};

export const extractDomain = (url) => {
  try {
    const domain = new URL(url).hostname;
    return domain?.replace("www.", "");
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};
