import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import moment from "moment-timezone";
import timezoneRefs from "utils/timezones.json";
import {
  DEFAULT_TOPIC_FOLDER,
  EXPLANATION_STYLES,
  PLATFORM_CONFIG,
} from "./constants";
import { Box } from "@mui/material";

/*
Constructs a map object from the timezone array by offset
{
    "-4": "America/New_York",
}
*/
export const timezoneMap = timezoneRefs.reduce(function (obj, elem) {
  obj[elem.Offset] = elem.StandardName;
  return obj;
}, {});

export function getRandomId() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function removeTimezoneFromDate(date) {
  if (date) {
    return date.replace(/(?:Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])/, "");
  }
  return date;
}

export function capitalizeWords(str) {
  if (str) {
    return str.replace(/\b\w/g, (l) => l.toUpperCase());
  }
  return str;
}

export function getPlatformLabel(platform) {
  return PLATFORM_CONFIG[platform].name ?? platform;
}

export const cleanDataTestId = (str) => {
  if (!str || typeof str !== "string") {
    return "";
  }

  str = str.trim();

  let newId = str;

  if (str.includes(" ")) {
    newId = str
      .trim()
      .split(" ")
      .map((d) => capitalizeWords(d.toLowerCase()))
      .join("");
  }

  if (!newId.endsWith("Icon")) {
    newId += "Icon";
  }

  return newId;
};

export function clearProjectFiles(projectFiles) {
  return projectFiles.map((path) => {
    const splitted = path.split("/");
    return splitted.slice(-1)[0];
  });
}

export function formatElasticSearchLabel(label) {
  let localLabelArr = label.split("_");
  let newLabel = "";
  for (let x = 0; x < localLabelArr.length; x++) {
    if (localLabelArr[x]) {
      newLabel += localLabelArr[x].charAt(0).toUpperCase();
      newLabel += localLabelArr[x].substr(1);
      if (x < localLabelArr.length - 1) {
        newLabel += " ";
      }
    }
  }
  return newLabel;
}

/** Returns a timezone object based on the stored offset. */
export const getTimeZoneObjectByOffset = (offset) => {
  const timezone = timezoneRefs.find((x) => x.Offset === offset);
  return timezone;
};

/** Returns a display value for an offset stored for a user. */
export const getTimezoneString = (timezoneOffset) => {
  const timeZone = getTimeZoneObjectByOffset(timezoneOffset);
  if (!timeZone) return ""; // When no timezone provided or found, it indicates local timezone.

  return `(${timeZone.RelativeLabel}) ${timeZone.Description}`;
};

/** Maps a timezone object into a key value pair that can be used for dropdown lists. */
const timezoneListMapper = (timezone) => ({
  label: getTimezoneString(timezone.Offset),
  value: timezone.Offset,
});

/** A list of timezones that can be used for drop down selection. */
export const timeZoneList = timezoneRefs.map(timezoneListMapper);

/** Gets a timezone and returns its correct offset based on the daylight saving status of the current moment. */
const getAdjustedTimeZoneOffset = (timeZone) => {
  const formatOptions = {
    timeZone: timeZone,
    hour: "2-digit",
    timeZoneName: "shortOffset",
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", formatOptions);
  const formattedDateString = formattedDate.format(new Date());
  const match = formattedDateString.match(/GMT([+-]\d+)/);
  return match ? +match[1] : undefined;
};

/** Gets a timezone offset, find its standard name, and returns the correct offset based on the daylight saving status of the current moment. */
const correctTimeZoneOffset = (timezoneOffset) => {
  const timeZone = getTimeZoneObjectByOffset(timezoneOffset);
  const name = timeZone?.StandardName;
  const adjustedOffset = name && getAdjustedTimeZoneOffset(name);
  return adjustedOffset ?? timezoneOffset;
};

/** Returns the correct UTC string date, based on the date adjusted for a timezone offset.
 * Example: "2020-01-01T09:00:00:000Z" with Offset -5 (EST - After daylight saving) -> "2020-01-01T13:00:00:000Z" (+4 Instead of +5)
 * @param {String} dateString ISO date string
 * @param {Number} timezoneOffset
 * @returns ISO date string adjusted for a timezone offset
 */
export const offsetDate = (dateString, timezoneOffset = 0) => {
  if (!dateString) {
    return "";
  }

  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[timezoneOffset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  // Create a moment instance in the desired timezone. Removing Z to treat as local time.
  const adjustedDate = moment.tz(
    dateString.replace("Z", ""),
    timezoneStandardName
  );

  // Convert the date to UTC
  const adjustedDateString = adjustedDate.toISOString();

  return adjustedDateString;
};

/**
 * Converts date from UTC to selected timezone
 * @param {String} dateString UTC date string
 * @param {Number} timezoneOffset selected timezone offset
 * @returns ISO date string adjusted for a timezone offset
 */
export const fromUTCtoSelectedTimezone = (dateString, timezoneOffset = 0) => {
  if (!dateString) {
    return "";
  }

  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[timezoneOffset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  // Create a moment instance in the desired timezone. Removing Z to treat as local time.
  const adjustedDate = moment.utc(dateString).tz(timezoneStandardName);

  return adjustedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

/** Returns the correct string date in "M/DD/YYYY h:mm A" format
 * Shows date in the selected timezone */
export const formatDateSlash = (dateString, timezoneOffset = 0) => {
  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[timezoneOffset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  const formatText = moment
    .utc(dateString)
    .tz(timezoneStandardName)
    .format("M/DD/YYYY h:mm A");
  return formatText;
};

/** Returns the correct string date in "MM/DD/YYYY" format
 * Shows date in the selected timezone
 */
export const formatDate = (dateString, timezoneOffset = 0) => {
  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[timezoneOffset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  const formatText = moment
    .utc(dateString)
    .tz(timezoneStandardName)
    .format("MM/DD/YYYY");
  return formatText;
};

export function getActivityChartDomain(engagements, posts) {
  if (!engagements?.length || !posts?.length) return [];

  const startDate = Math.min(
    engagements?.[0]?.timestamp,
    posts?.[0]?.timestamp
  );

  const endDate = Math.max(
    engagements?.[engagements.length - 1]?.timestamp,
    posts?.[posts.length - 1]?.timestamp
  );

  return [
    moment(startDate).startOf("day").toDate(),
    moment(endDate).startOf("day").toDate(),
  ];
}

export function formatMonthAbrDayFromTimestamp(ts) {
  if (!ts) return null;
  const date = new Date(ts);
  return moment(date).format("MMM DD, YYYY");
}

export function formatMonthAbrDayFromDate(
  dateString,
  timezoneOffset,
  format = "MMM DD"
) {
  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[timezoneOffset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  const formatText = moment
    .utc(dateString)
    .tz(timezoneStandardName)
    .format(format);
  return formatText;
}

export function formatMonthAbrDay(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (date) {
    const [year, month, day] = date.split("T")[0].split("-");
    const m = +month;
    return `${months[m - 1]} ${+day}, ${year}`;
  }
  return null;
}

export function subtructDays(date, days) {
  const _date = getDate(date);
  return formatWithDash(_date.setDate(_date.getDate() - days));
}

export function addDays(date, days) {
  const _date = getDate(date);
  return formatWithDash(_date.setDate(_date.getDate() + days));
}

function getDate(date) {
  const [year, month, day] = date.split("T")[0].split("-");
  return new Date(+year, +month - 1, +day);
}

export function secondsToDhm(seconds) {
  seconds = Number(seconds);
  if (seconds < 60) return " < 1 minute ";
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
  return dDisplay + hDisplay + mDisplay;
}

function formatWithDash(date) {
  return timeFormat("%Y-%m-%d")(date);
}

const convertDayToUtc = (time, weekDay) => {
  const [hours, minutes] = time.split(":");
  const now = new Date();
  now.setDate(now.getDate() + ((weekDay + 7 - now.getDay()) % 7));
  now.setHours(+hours, +minutes, 0);
  return now.getUTCDay();
};

const convertTimeToUtc = (time) => {
  const [hours, minutes] = time.split(":");

  const now = new Date();

  if (hours) {
    now.setHours(+hours);
  }

  if (minutes) {
    now.setMinutes(+minutes);
  }

  const utcHours = now.getUTCHours();
  const utcMinutes = now.getUTCMinutes();

  return `${utcHours < 10 ? "0" + utcHours : utcHours}:${
    utcMinutes < 10 ? "0" + utcMinutes : utcMinutes
  }`;
};

export const convertTimeToLocale = (time) => {
  const [hours, minutes] = time.split(":");
  const now = new Date();

  if (hours) {
    now.setUTCHours(+hours);
  }

  if (minutes) {
    now.setUTCMinutes(+minutes);
  }

  const localHours = now.getHours();
  const localMinutes = now.getMinutes();

  return `${localHours < 10 ? "0" + localHours : localHours}:${
    localMinutes < 10 ? "0" + localMinutes : localMinutes
  }`;
};

export const convertToLocale = (input, nextJobRun) => {
  const config = { ...input };

  if (config.day && config.frequency === "weekly") {
    config.day = moment(new Date(nextJobRun))
      .format("dddd")
      .toString()
      .toLowerCase();
    config.time = convertTimeToLocale(config.time);
  } else if (config.frequency === "twice-daily") {
    if (config.time1) {
      config.time1 = convertTimeToLocale(config.time1);
    }
    if (config.time2) {
      config.time2 = convertTimeToLocale(config.time2);
    }
  } else if (config.time) {
    config.time = convertTimeToLocale(config.time);
  }

  return config;
};

const weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const convertToUTC = (input) => {
  const config = { ...input };

  if (config.day && config.frequency === "weekly") {
    const weekDay = weekdays.indexOf(config.day);

    if (weekDay > -1) {
      config.day = weekdays[convertDayToUtc(config.time, weekDay)];
      config.time = convertTimeToUtc(config.time);
    }
  } else if (config.frequency === "twice-daily") {
    if (config.time1) {
      config.time1 = convertTimeToUtc(config.time1);
    }
    if (config.time2) {
      config.time2 = convertTimeToUtc(config.time2);
    }
  } else if (config.time) {
    config.time = convertTimeToUtc(config.time);
  }
  return config;
};

/**
 * Converts UTC date string to selected timezone
 * E.g: Offset = -4 (EST) 2023-03-17T16:34:35.000Z -> Mar. 17, 2023 12:34 PM
 * @param {String | Number} date UTC date time string or unix timestamp
 * @param {Number} offset
 * @returns formatted date string
 */
export function formatPostTime(date, offset = 0) {
  // Sanity check
  if (!date) {
    return "-";
  }

  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[offset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  return moment
    .utc(date)
    .tz(timezoneStandardName)
    .format("MMM. DD, YYYY h:mm A");
}

/**
 * Converts UTC date string to selected timezone
 * E.g: Offset = -4 (EST) 2023-03-17T16:34:35.000Z -> Mar. 17, 2023 12:34 PM
 * @param {String | Number} date UTC date time string or unix timestamp
 * @param {Number} offset
 * @returns formatted date string
 */
export function formatPostDate(date, offset = 0) {
  // Sanity check
  if (!date) {
    return "-";
  }

  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[offset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  return moment.utc(date).tz(timezoneStandardName).format("MMM. DD, YYYY");
}

export const getPlatformName = (platform) => {
  const platformConfig = PLATFORM_CONFIG[platform];
  if (platformConfig) {
    return platformConfig.name;
  }
  return capitalizeWords(platform);
};

export function formatPlatforms(platforms) {
  if (Array.isArray(platforms)) {
    return platforms.map(getPlatformName).join(", ");
  }
  return getPlatformName(platforms);
}

/**
 * Converts UTC date string to selected timezone
 * E.g: Offset = -4 (EST) 2023-03-17T16:34:35.000Z -> Mar. 17, 2023 12:34 PM
 * @param {String | Number} date UTC date time string or unix timestamp
 * @param {Number} offset
 * @returns formatted date and time string
 */
export function formatDateTime(date, offset = 0, showTimezone = false) {
  // Sanity check
  if (!date) {
    return "-";
  }

  // Get the timezone from the timezone map
  const timezoneStandardName = timezoneMap[offset.toString()];

  // If timezoneStandardName is undefined, it means the offset is not in the list.
  if (!timezoneStandardName) {
    return "-";
  }

  const dateFormatted = moment
    .utc(date)
    .tz(timezoneStandardName)
    .format("M/DD/YYYY h:mm A");
  const timeAbbr = showTimezone
    ? " " + moment.tz(timezoneStandardName).zoneName()
    : "";
  const timeSplit = dateFormatted.split(" ");
  return `${timeSplit[0]}
  ${timeSplit[1]} ${timeSplit[2]}${timeAbbr}`;
}

export function getTimezoneDescriptionByOffset(offset) {
  const timezone = getTimeZoneObjectByOffset(offset);
  if (!timezone) {
    return "";
  }
  const RelativeLabel = timezone.RelativeLabel || "";
  const Description = timezone.Description || "";
  return `(${RelativeLabel}) ${Description}`;
}

export function formatNumber(num) {
  // If not a number, just return untouched
  if (isNaN(num)) {
    return num;
  }
  return format(",")(Math.round(num));
}

export function formatNumberK(num) {
  return format(".2~s")(Math.round(num));
}

export function formatNumberkANDcomma(val) {
  return val >= 1000 ? formatNumberK(val) : val;
}

function roundToThree(num) {
  return Math.floor(num * 1000) / 1000;
}

export function roundToTwo(num) {
  return Math.floor(num * 100) / 100;
}

export function roundToOne(num) {
  return Math.round(num * 10) / 10;
}

export function getPercent(num, total, precision = 3) {
  const formatFn =
    precision === 3 ? roundToThree : precision === 2 ? roundToTwo : roundToOne;
  return formatFn((num / (total || 1)) * 100);
}

export function getPercentStr(num, total, precision = 3) {
  return getPercent(num, total, precision) + "%";
}

export function getTicks(ticks, count, domain) {
  if (ticks.length === 1) {
    return ticks;
  }

  count = Math.max(4, count);

  domain = domain.map((d) => {
    if (d instanceof Date) {
      return new Date(d);
    }
    return d;
  });

  const data = [domain[0], ...ticks, domain[1]];

  const arr = [];
  const step = Math.floor(data.length / count);

  for (let i = 0; i < data.length; i++) {
    const tick = data[i];

    if (
      (i % step === 0 && i <= data.length - 1 - step) ||
      i === data.length - 1 ||
      step === 0
    ) {
      arr.push(tick);
    }
  }

  return arr;
}

/** Given the offset hours as a float, returns the ISO 8601 timezone offset */
export function formatTimezoneOffset(offset = 0) {
  const adjustedOffset = correctTimeZoneOffset(offset);
  const duration = moment.duration(Math.abs(adjustedOffset), "hours");
  const sign = adjustedOffset >= 0 ? "+" : "-";
  const hours = String(duration.hours()).padStart(2, "0");
  const minutes = String(duration.minutes()).padStart(2, "0");
  return `${sign}${hours}:${minutes}`;
}

export const DEFAULT_START_DATE = "2019-01-01T00:00:00.000Z";
const today = new Date();
export const DEFAULT_END_DATE = today.toISOString();

const buildStyleTag = (obj) => {
  return Object.keys(obj)
    .map((d) => {
      return `${d}: ${obj[d]};`;
    })
    .join("");
};

export const buildExplanationText = (textArr) => {
  return textArr
    .map((d) => {
      if (typeof d === "object") {
        const styleEntity = EXPLANATION_STYLES[d.style_entity];
        if (d.style_entity && styleEntity) {
          return `<span style="${buildStyleTag(styleEntity)}">${d.text}</span>`;
        }
        return d.text;
      }
      return d;
    })
    .join(" ")
    .trim();
};

export const calculateTopicFolders = (projects) => {
  const projectsByTopicName = {};

  for (const p of projects) {
    const project = { ...p };
    const { topics } = project;

    if (topics) {
      for (const topic of topics) {
        const topicName = topic.name;
        if (projectsByTopicName[topicName]) {
          projectsByTopicName[topicName].push(project);
        } else {
          projectsByTopicName[topicName] = [project];
        }
      }
    }

    if (!topics || topics.length === 0) {
      if (projectsByTopicName[DEFAULT_TOPIC_FOLDER]) {
        projectsByTopicName[DEFAULT_TOPIC_FOLDER].push(project);
      } else {
        projectsByTopicName[DEFAULT_TOPIC_FOLDER] = [project];
      }
    }
  }

  return Object.entries(projectsByTopicName)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .map((t) => ({ name: t[0], projects: t[1], expanded: false }));
};

export const replaceLineBreaks = (str) =>
  str
    .split("\n")
    .map((d) => d.trim())
    .filter((d) => d)
    .join(",");

export const getParagraphs = (text) => {
  return (text || "").split("\n").filter((d) => d.trim());
};

// this will get UTC string from current date
export const getISOString = (date, timeToReplace) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();

  const time =
    timeToReplace ||
    `${hour < 10 ? "0" + hour : hour}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;

  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }T${time}:00.000Z`;
};

export const getISOStringMoment = (date, time) => {
  const dateStr = date.format("YYYY-MM-DD");
  return `${dateStr}T${time}:00.000Z`;
};

/**
 * Checks if UTC date string is in the future
 * @param {String} dateString date string in UTC
 * @returns boolean
 */
export const checkIfInFuture = (dateString) => {
  return moment.utc(dateString).isAfter(moment.utc());
};

const capitalizeFirstLetter = (phrase) =>
  phrase.charAt(0).toUpperCase() + phrase.slice(1);

/** Automatically generate a display name by adding spaces to an upper camelcase string. */
export const refineDisplayName = (name) => {
  const chunks = name.split("_");
  const transformed = chunks.map(capitalizeFirstLetter);

  const nameLowered = name.toLowerCase();
  const dashMerge =
    nameLowered.includes("anti_") || nameLowered.includes("pro_");

  return transformed.join(dashMerge ? "-" : " ");
};

export const refineDomainName = (name) => {
  return (name || "")
    .replace("https://", "")
    .replace("http://", "")
    .split("/")[0];
};

export const getQuestionsFromString = (questions) => {
  const questionArray = questions.split("\n");
  const formattedQuestions = questionArray.map((question) => {
    if (question.trim() === "") return;
    const questionNumber = question.split(".")[0];
    const questionText = question.split(".").slice(1).join(".").trim() || "";
    return { number: questionNumber, text: questionText };
  });

  return formattedQuestions;
};

export const formatReportLines = (inputString, modelString) => {
  const inputStringNoNewlines = inputString.replace(/^\n+/, "");

  const dateColor = "#79B1FF";
  const dollarColor = "#28c76f";
  const numColor = "#7AC13E";
  const userScreennameColor = "#B3A4F0";

  const dateRegex =
    /(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?)\s+\d{1,2},\s+\d{4}/g;
  const numRegex =
    /(?<!<span)\s[1-9]\d{0,2}(?:[kKmM]{1})\s|\s\d+(?:\.\d{1,2})?[kKmM]{1}\s|\s\d+\s(?!<\/span>)/g;
  const dollarRegEx = /(\$\d+(?:[.,]\d{0,3})*(?:[.,]\d{0,3}))/g;
  const userScreennameRegEx = /(@[a-zA-Z0-9_]+)/g;

  const dollarFormatted = '<span style="color: ' + dollarColor + '">$&</span>';
  const dateFormatted = '<span style="color: ' + dateColor + '">$&</span>';
  const numFormatted = '<span style="color: ' + numColor + '">$&</span>';
  const userScreennameFormatted =
    '<span style="color: ' + userScreennameColor + '">$&</span>';

  // Replace formatting
  const outputString = inputStringNoNewlines
    .replaceAll(dateRegex, dateFormatted)
    .replaceAll(numRegex, numFormatted)
    .replaceAll(dollarRegEx, dollarFormatted)
    .replaceAll(userScreennameRegEx, userScreennameFormatted);

  const outputStringBullets = bulletPointsToList(outputString);
  const lines = outputStringBullets.split(/\r?\n/);

  const toReturn = lines.map((line, i) => {
    return (
      <Box
        sx={{
          "& ul": {
            marginBottom: "0px",
          },
          "& ::marker": {
            color: "#39F3C5",
            fontSize: "1.2rem",
          },
          "& ul li": {
            paddingBottom: ".7rem",
          },
        }}
        dangerouslySetInnerHTML={{ __html: line + "<br />" }}
        key={i}
      />
    );
  });
  if (modelString !== "text-davinci-003" && !modelString.startsWith("gpt")) {
    toReturn.push(
      <span
        style={{ color: "#747373", fontSize: "14px", fontStyle: "italic" }}
        dangerouslySetInnerHTML={{ __html: "LLM2" }}
        key={lines.length}
      />
    );
  }
  return toReturn;
};

function bulletPointsToList(str) {
  let listItems = str.split("•");
  if (listItems.length === 1) {
    return str;
  }
  let newStr = `<ul>`;
  listItems.forEach((item) => {
    if (item.trim() !== "") {
      newStr += `<li>${item.trim()}</li>`;
    }
  });
  newStr += "</ul>";
  return newStr;
}

/**
 * Replaces post_body with highlight_text if highlight_text is not the same as post_body
 * @param {String} post_body full post body text
 * @param {String} highlight_text highlighted portion of post body
 * @returns updated post body
 */
export const fixHighlightText = (post_body, highlight_text) => {
  let text = highlight_text;

  if (post_body && highlight_text !== post_body) {
    // remove <em> tags
    const text_to_remove = highlight_text.replace(/<\/?em>/g, "");

    // replace bare highlight portion with highlighted text
    text = post_body.replace(text_to_remove, highlight_text);
  }

  return text;
};
